import React from 'react'
import { LocalGasStation, ShoppingCart, Receipt } from '@mui/icons-material';

import './home.css'
import GasStation from '../Assets/GasStation.gif'
import GasBooth from '../Assets/Gas-Booth.png'

import Header from '../Components/Header/Header'
import BenefitsCard from '../Components/Cards/BenefitsCard';

export default function Home() {
  return (
    <div>
      <div>
        <Header/>
          <div className='row justify-content-center'>
            <div className='d-flex justify-content-center align-items-center col-lg-4 col-12'>
              <div>
                <h3 className='text-center sub-text'>CLOUD BASED V2</h3>
                <h1 className='text-center title-text'>GAS STATION SYSTEM</h1>
                <h4 className='text-center' style={{fontFamily: 'Sofia'}}>Accuracy of accounts <br/> lead the business to Success</h4>
              </div>
            </div>

            <div className='col-lg-6 col-12'>
              <img
                style={{borderRadius: '40% 30% 20% 20% / 50% 20% 70% 20%'}}
                src={GasStation}
                className='w-100'
              />
            </div>
          </div>
      </div>

      <div className='pt-5 p-3'>
        <h3 className='text-center' style={{fontFamily: 'Trirong'}}>BENEFITS OF SYSTEM</h3>
        <div className='row justify-content-center pt-2'>
          <div className='col-md-3 col-12'>
            <BenefitsCard
              icon = {<LocalGasStation fontSize='large'/>}
              title= "FUEL"
              description = "All essential features required to fuel management has added to the System. Starts at Meter Reading all the way to Tank Checks"
            />
          </div>

          <div className='col-md-3 col-12'>
            <BenefitsCard
              icon = {<ShoppingCart fontSize='large'/>}
              title= "POS"
              description = "Inventory Sales System added along with FIFO Inventory Management. As initial purchase to final outcome of sales reports"
            />
          </div>

          <div className='col-md-3 col-12'>
            <BenefitsCard
              icon = {<Receipt fontSize='large'/>}
              title= "ACCOUNTS"
              description = "Key component of Business analysis has injected with double entry with categorize accounts, Income Statement and Balance Sheet"
            />
          </div>



          {/* <div className='col-lg-4'>
            <img
              width={400}
              src={GasBooth}
            />
          </div> */}

          {/* <div className='col-lg-8'>
            <h4> Gas Station Management</h4>
            <p>The Component use to manage all activities of fuel. From the begining Fuel purchase to end sell</p>
            <ol>
              <li>Purchase Fuel</li>
              <ul>
                <li>Accounts and Stocks updates Automatically</li>
              </ul>
              <li>Mark Pumps Readings</li>
              <ul>
                <li>Calculate the income of particular Pump</li>
              </ul>
              <li>Record accounts of employees</li>
              <ul>
                <li>Calulate and summarize all accounts</li>
              </ul>
              <li>Compare Employee Accounts with Pump in-come</li>
              <ul>
                <li>Calulate and summarize all accounts</li>
              </ul>
            </ol>
          </div> */}

        </div>
      </div>
    </div>
  )
}
