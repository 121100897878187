import React from 'react'
import './header.css'

export default function Header() {
  return (
    <div className='d-flex justify-content-end gap-3 p-3 m-3' style={{backgroundColor: 'rgba(0,0,0, 0.7)'}}>
      <button className='btn btn-link text-decoration-none menu-items'>Home</button>
      <button className='btn btn-link text-decoration-none menu-items'>Demo</button>
      <button className='btn btn-link text-decoration-none menu-items'>Affiliate</button>
      <button className='btn btn-link text-decoration-none menu-items'>Pricing</button>
      <button className='btn btn-link text-decoration-none menu-items'>About Us</button>
      <button className="btn rectangle text-change" style={{fontFamily: 'sans-serif'}}><div>CONTACT</div></button>
    </div>
  )
}
