import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';


export default function BenefitsCard(props) {
  return (
    <Card key={props.title} className='p-3'>
        <CardContent>
          <div className='text-center pb-2'>{props.icon}</div>
          <h5 className='text-center fw-bold' style={{fontFamily: 'Trirong'}}>
            {props.title}
          </h5>
          <p style={{textAlign: 'justify'}}>
            {props.description}
          </p>
        </CardContent>
    </Card>
  );
}
