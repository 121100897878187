import Home from "./Pages/Home";

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

function App() {

  const MUITheme = createTheme({
    palette: {
      mode: `dark`,
    },
  });

  return (
    <ThemeProvider theme={MUITheme}>
    <CssBaseline/>
      <div>
        <div className='vh-100'>
          <Home/>
        </div>
      </div>
    </ThemeProvider>

  );
}

export default App;
